var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isAdmin)?[_c('v-card',{staticClass:"pa-3 mb-3",attrs:{"flat":"","outlined":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pr-0",attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"vid":"startDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Data Início","hide-details":"auto","append-icon":"mdi-calendar","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}})]}}],null,false,3513333100)})],1),_c('v-col',{staticClass:"pr-0",attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"vid":"endDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Data Fim","hide-details":"auto","append-icon":"mdi-calendar","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}})]}}],null,false,1901696750)})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-btn',{attrs:{"color":"info","disabled":_vm.customersLoading && _vm.techniciansLoading,"loading":_vm.customersLoading && _vm.techniciansLoading,"depressed":""},on:{"click":_vm.loadDashboard}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-text-box-search-outline')}}),_vm._v(" Consultar ")],1)],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-3",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-h4 font-weight-bold"},[_vm._v("Clientes")])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"mx-2 pt-0 mt-0",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","filled":"","dense":"","clearable":""},model:{value:(_vm.customersSearch),callback:function ($$v) {_vm.customersSearch=$$v},expression:"customersSearch"}}),_c('v-data-table',{attrs:{"dense":"","server-items-length":_vm.customersCount,"loading":_vm.customersLoading,"headers":_vm.customersHeaders,"items":_vm.customers,"options":_vm.customersTableOptions,"footer-props":{
              'items-per-page-options': [5, 10, 20],
            }},on:{"update:options":[function($event){_vm.customersTableOptions=$event},_vm.loadCustomers]}})],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-3",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-h4 font-weight-bold"},[_vm._v("Técnicos")])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"mx-2 pt-0 mt-0",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","filled":"","dense":"","clearable":""},model:{value:(_vm.techniciansSearch),callback:function ($$v) {_vm.techniciansSearch=$$v},expression:"techniciansSearch"}}),_c('v-data-table',{attrs:{"dense":"","server-items-length":_vm.techniciansCount,"loading":_vm.techniciansLoading,"headers":_vm.techniciansHeaders,"items":_vm.technicians,"options":_vm.techniciansTableOptions,"footer-props":{
              'items-per-page-options': [5, 10, 20],
            }},on:{"update:options":[function($event){_vm.techniciansTableOptions=$event},_vm.loadTechnicians]}})],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }