<template>
  <v-container fluid>
    <template v-if="isAdmin">
      <v-card flat outlined class="pa-3 mb-3">
        <v-row class="align-center">
          <v-col md="3" class="pr-0">
            <validation-provider v-slot="{ errors }" vid="startDate">
              <v-text-field
                v-model="filters.startDate"
                v-mask="'##/##/####'"
                label="Data Início"
                hide-details="auto"
                append-icon="mdi-calendar"
                outlined
                dense
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col md="3" class="pr-0">
            <validation-provider v-slot="{ errors }" vid="endDate">
              <v-text-field
                v-model="filters.endDate"
                v-mask="'##/##/####'"
                label="Data Fim"
                hide-details="auto"
                append-icon="mdi-calendar"
                outlined
                dense
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col md="3">
            <v-btn
              color="info"
              :disabled="customersLoading && techniciansLoading"
              :loading="customersLoading && techniciansLoading"
              @click="loadDashboard"
              depressed
            >
              <v-icon v-text="'mdi-text-box-search-outline'" left />
              Consultar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-card flat outlined class="pa-3 mb-3">
        <v-row>
          <v-col>
            <p class="text-h4 font-weight-bold">Clientes</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-text-field
              class="mx-2 pt-0 mt-0"
              v-model="customersSearch"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
              filled
              dense
              clearable
            />
            <v-data-table
              dense
              :server-items-length="customersCount"
              :loading="customersLoading"
              :headers="customersHeaders"
              :items="customers"
              :options.sync="customersTableOptions"
              :footer-props="{
                'items-per-page-options': [5, 10, 20],
              }"
              @update:options="loadCustomers"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>

      <v-card flat outlined class="pa-3 mb-3">
        <v-row>
          <v-col>
            <p class="text-h4 font-weight-bold">Técnicos</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-text-field
              class="mx-2 pt-0 mt-0"
              v-model="techniciansSearch"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
              filled
              dense
              clearable
            />
            <v-data-table
              dense
              :server-items-length="techniciansCount"
              :loading="techniciansLoading"
              :headers="techniciansHeaders"
              :items="technicians"
              :options.sync="techniciansTableOptions"
              :footer-props="{
                'items-per-page-options': [5, 10, 20],
              }"
              @update:options="loadTechnicians"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import customersApi from '@/api/customers'
import usersApi from '@/api/users'
import dateFormat from '@/utils/date-format'

const defaultTableOptions = {
  itemsPerPage: 5,
  sortBy: ['countReports'],
  sortDesc: [1],
}

export default {
  data() {
    return {
      dateFormatIso: dateFormat.iso,
      filters: {
        startDate: dateFormat.getFirstDayOfMonth(),
        endDate: dateFormat.getLastDayOfMonth(),
      },
      // Customers
      customers: [],
      customersLoading: false,
      customersCount: 0,
      customersSearch: '',
      customersHeaders: [
        { text: 'Cliente', value: 'companyName' },
        { text: 'Nº relatórios', value: 'countReports' },
      ],
      customersTableOptions: defaultTableOptions,
      customersSearchTimeout: null,
      // Technicians
      technicians: [],
      techniciansLoading: false,
      techniciansCount: 0,
      techniciansSearch: '',
      techniciansHeaders: [
        { text: 'Técnico', value: 'name' },
        { text: 'Nº relatórios', value: 'countReports' },
      ],
      techniciansTableOptions: defaultTableOptions,
      techniciansSearchTimeout: null,
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
  },

  watch: {
    customersSearch() {
      clearTimeout(this.customersSearchTimeout)
      this.customersSearchTimeout = setTimeout(this.loadCustomers, 500)
    },
    techniciansSearch() {
      clearTimeout(this.techniciansSearchTimeout)
      this.techniciansSearchTimeout = setTimeout(this.loadTechnicians, 500)
    },
  },

  methods: {
    async loadDashboard() {
      this.loadCustomers()
      this.loadTechnicians()
    },

    async loadCustomers() {
      try {
        this.customersLoading = true

        const response = await customersApi.listDashboard(
          this.getParams('customers'),
        )

        this.customers = response.data.customers
        this.customersCount = response.data.count
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.customersLoading = false
      }
    },

    async loadTechnicians() {
      try {
        this.techniciansLoading = true

        const response = await usersApi.listDashboard(
          this.getParams('technicians'),
        )

        this.technicians = response.data.users
        this.techniciansCount = response.data.count
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.techniciansLoading = false
      }
    },

    getParams(table) {
      const tableOptions =
        table === 'customers'
          ? this.customersTableOptions
          : this.techniciansTableOptions

      const search =
        table === 'customers' ? this.customersSearch : this.techniciansSearch

      const offset =
        tableOptions.page * tableOptions.itemsPerPage -
        tableOptions.itemsPerPage

      return {
        limit: tableOptions.itemsPerPage,
        offset,
        startDate: dateFormat.iso(this.filters.startDate),
        endDate: dateFormat.iso(this.filters.endDate),
        search: search || '',
        sortBy: tableOptions.sortBy?.[0] || '',
        sortDesc: tableOptions.sortDesc?.[0] ? 1 : 0,
      }
    },
  },
}
</script>
