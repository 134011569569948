import axios from '@/plugins/axios'

export default {

  async list (data) {
    const parametros = new URLSearchParams(data).toString()
    return await axios.get(`users?${parametros}`)
  },

  async listTechnicians (data) {
    const parametros = new URLSearchParams(data).toString()
    return await axios.get(`technicians?${parametros}`)
  },

  async get (id) {
    return await axios.get(`users/${id}`)
  },

  async insert (data) {
    return await axios.post('users', data)
  },

  async update (id, data) {
    return await axios.put(`users/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`users/${id}`)
  },

  async changePassword (id, data) {
    return await axios.patch(`users/${id}/change-password`, data)
  },

  async listDashboard (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`dashboard/users?${params}`)
  },

}
