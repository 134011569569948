import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`customers?${params}`)
  },

  async get (id) {
    return await axios.get(`customers/${id}`)
  },

  async insert (data) {
    return await axios.post('customers', data)
  },

  async update (id, data) {
    return await axios.put(`customers/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`customers/${id}`)
  },

  async listContacts (customerId, data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`customers/${customerId}/contacts?${params}`)
  },

  async insertContact (id, data) {
    return await axios.post(`customers/${id}/contacts`, data)
  },

  async updateContact (customerId, contactId, data) {
    return await axios.put(`customers/${customerId}/contacts/${contactId}`, data)
  },

  async deleteContact (customerId, contactId) {
    return await axios.delete(`customers/${customerId}/contacts/${contactId}`)
  },

  async listDashboard (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`dashboard/customers?${params}`)
  },

}
